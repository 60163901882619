<template>
	<div class="asideLeft">
		<div class="asideBg" @click="handleAsideHide"></div>
		<div class="asideCon">
			<div class="backBtn" @click="handleAsideHide"><i class="icon_arrL"></i></div>
			<div class="userInfo">
				<div class="avatar">
					<!-- <img class="userHead" v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
					<img class="userHead" v-else src="@/assets/imgs/login/rainbow.png" /> -->
					<img class="userHead" :src="avatar_img" v-if="avatar_img != ''"/>
					<div class="level" v-if="vipInfo.active">
						<img src="@/assets/imgs/icon/icon_vip.png" alt="" />
						<span>VIP {{ vipInfo.level }}</span>
					</div>
				</div>
				<div class="info">
					<p class="nike">{{ userInfo.username }}</p>
					<div class="deadline">
						<!-- 是否为vip -->
					    <p v-if="vipInfo.active">
							{{ vipInfo.expire_day }} {{ $t('tba.deadline')}}
					    </p>
					    <p v-else>{{ $t('tba.youarenotVip')}}</p>
					</div>
				</div>
			</div>
			<div class="adPoster" @click="routerTo('/memberClub')"><img src="@/assets/imgs/banner.png" alt="" /></div>
			
			<div class="list">
				<list-icon-arr :label="$t('xinzeng.chongbi')" linkUrl="/chargeMoney">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_charge.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('xinzeng.tibi')" linkUrl="/withdrawMoney">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_carry.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.invitefriends')" linkUrl="/sharePage">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_invitation.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.myteam')" linkUrl="/community2">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_team.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.TransactionRecord')" linkUrl="/transactionRecords">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_record.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.tool')" linkUrl="/">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_tool.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.Securitycenter')" linkUrl="/securityCenter">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_security.png" alt="" />
				</list-icon-arr>
				<!-- <list-icon-arr :label="$t('tba.Helpcenter')" linkUrl="/viewArticle" :queryParams="{id: this.helpId}"> -->
				<list-icon-arr :label="$t('tba.article')" linkUrl="/consultingService">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_help.png" alt="" />
				</list-icon-arr>
				<list-icon-arr :label="$t('tba.aboutus')" linkUrl="/aboutOurs">
					<img slot="icon" src="@/assets/imgs/icon/my_icon_about.png" alt="" />
				</list-icon-arr>
			</div>
			
			<div class="logOutBtn" @click="logOut">{{ $t('tba.dropout') }}</div>
		</div>
	</div>
</template>

<script>
	import listIconArr from '@/components/list/listIconArr.vue'
	import { logout } from "@/router/index.js"
	export default {
		name: 'asideLeft',
		components: {
			listIconArr,
		},
		data() {
			return {
				userInfo: {},
				expire_day: '',
				activeIfVar: false,
				helpId: '',
				vipInfo: {},
				avatar_img: ''
			}
		},
		mounted() {
			// 获取用户信息
			let userinfo = JSON.parse(localStorage.getItem('userInfo'))
			this.userInfo = userinfo
			// console.log(userinfo)
		},
		created() {
			this.GetSystemImgList()
			this.getHelpID()
			this.getClubMemberInfo()
		},
		methods: {
			routerTo(path) {
				this.$router.push(path)
			},
			// 获取帮助中心文章id
			getHelpID() {
				this.$post2('Article/Api/Index/getHelpID').then(res => {
					this.helpId = res.id;
				});
			},
			// 验证是否为会员用户，获取用户会员等级信息
			getClubMemberInfo() {
			    this.$post2("User/Api/Index/getClubInfo", {}).then((res) => {
					this.vipInfo = res
					// console.log('vipInfo', this.vipInfo)
			    });
			},
			handleAsideHide() {
				this.$emit('handleAsideHide')
			},
			logOut() {
				console.log('logOut')
				this.$post2("user/api/passport/logout", {})
				    .then(res => {
				        logout()
				    })
			},
			//获取logo图
			GetSystemImgList() {
				this.$post2('User/Api/Passport/GetSystemImgList  ', { noToast: true })
					.then(res => {
						this.avatar_img = res.list[0].url;
					})
					.catch(e => {
						// this.$toast.fail(e);
					});
			}
		},
	}
</script>

<style scoped lang="less">
	.asideLeft {
		position: fixed;
		z-index: 100;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		.asideBg {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			background: rgba(4, 4, 15, 0.4);
		}
		.asideCon {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			z-index: 2;
			width: 6.15rem;
			// padding: .88rem .3rem .3rem;
			padding: 0 .3rem .3rem;
			background: #FFFFFF url(../assets/imgs/aside_bg.png) no-repeat center top;
			background-size: 100% auto;
			overflow-y: auto;
		}
		.backBtn {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.userInfo {
		padding: .2rem .25rem;
		background: rgba(255, 255, 255, 0.16);
		border-radius: .12rem;
		display: flex;
		align-items: center;
		margin-bottom: .25rem;
		.avatar {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: .25rem;
			.userHead {
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
			.level {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: -.2rem;
				img {
					width: .4rem;
					height: .4rem;
					display: block;
					margin-right: -.25rem;
					position: relative;
					z-index: 2;
				}
				span {
					color: #FFFFFF;
					font-size: .12rem;
					width: 1rem;
					height: .24rem;
					line-height: .24rem;
					padding-left: .3rem;
					background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
					border: 1px solid #FFFFFF;
					border-radius: .16rem;
					display: block;
					position: relative;
					z-index: 1;
				}
			}
		}
		.nike {
			color: #373737;
			font-size: .36rem;
			line-height: .5rem;
			margin-bottom: .08rem;
		}
		.deadline {
			color: #666666;
			font-size: .24rem;
		}
	}
	
	.adPoster {
		height: 1.35rem;
		margin-bottom: .5rem;
		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.logOutBtn {
		color: #FFFFFF;
		font-size: .32rem;
		height: .88rem;
		line-height: .88rem;
		text-align: center;
		background: #D1D1D6;
		border-radius: .12rem;
		margin-top: 1rem;
	}
</style>
