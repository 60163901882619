<template>
	<div class="menuList" @click="routerTo">
		<div class="icon"><slot name="icon"></slot></div>
		{{ label }}
	</div>
</template>


<script>
	export default {
		name: 'menuList',
		props: {
			label: {
				type: String,
				default: ''
			},
			linkUrl: {
				type: String,
				default: '/'
			},
		},
		methods: {
			routerTo() {
				if (this.linkUrl && this.linkUrl !== '/') {
				    this.$router.push(this.linkUrl);
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.menuList {
		font-family: PingFang SC;
		width: 25%;
		color: #666666;
		font-size: 0.24rem;
		transform: scale(0.916);
		text-align: center;
		margin-bottom: 0.15rem;
		text-transform: capitalize;
		.icon {
			width: 0.64rem;
			height: 0.64rem;
			margin: 0 auto;
			margin-bottom: 0.08rem;
			img {
				display: block;
				width: 100%;
			}
		}
	}
</style>
